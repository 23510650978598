<template>
  <div >
<!--    <div class="title">-->
<!--        <home-doc-list-title content="学习专栏" :active="true" ></home-doc-list-title>-->
<!--    </div>-->
    <div class="containerlist">
      <el-row>
        <el-col :span="screenWidth>=1000?8:24" v-if="categories.length" v-for="(item,index) in categories" @click.native="$router.push('/doclist?id=8&categoryid='+index)">
          <img :src="item.banner" style="width: 98%;height: 150px;margin: 20px 0; object-fit: cover;cursor: pointer"/>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import HomeDocListTitle from "@/components/Home/HomeDocListTitle";
export default {
  name: "HomeBannerList",
  components: {HomeDocListTitle},
  data(){
    return{
      categories:[],
      screenWidth: document.body.clientWidth,
    }
  },
  mounted() {
    this.axios.get('/api/document/user/section/restrieve/'+8+'/')
        .then(res=>{
          this.categories = res.data.category.slice(0,3)
        })
  }
}
</script>

<style scoped>
.containerlist{
  box-shadow:0 0 8px 3px rgba(0,0,0,0.15);
  margin: 0px 10px 10px 10px;
  padding: 10px 25px;
}
.title{
  display: flex;
  border-bottom: 1px solid rgb(235, 239, 242);
  margin-bottom: 15px;
  position: relative;
  flex-direction: row;
  box-sizing: border-box
}
</style>