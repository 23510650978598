<template>
  <div>
    <Header></Header>
    <BGImg :is-home="true" :banner-list="banner_list"></BGImg>
<!--  这里要写总共加载多少个category -->
    <div v-if="oknum >= 5" style="max-width: 1220px;margin: auto;margin-top: 50px;">
      <div class="row-container" style="box-shadow:0 0 8px 3px rgba(0,0,0,0.15);">
        <HomeCarousel v-if="carousels.length > 0" class="carousel" :data="carousels"></HomeCarousel>
        <home-doc-list class="doc_list_wide" title-type="wide"
                       :docs="[categories['公司动态'],categories['国资要闻'],categories['集团资讯'],categories['时政新闻'],categories['区域资讯']]"
                       >
        </home-doc-list>

      </div>
      <div class="row-container">

        <home-doc-list class="doc_list_narrow" title-type="narrow" :docs="[categories['党建工作']]" ></home-doc-list>
        <home-doc-list class="doc_list_narrow" title-type="narrow" :docs="[categories['市场经营']]" ></home-doc-list>
        <home-doc-list class="doc_list_narrow" title-type="narrow" :docs="[categories['人力资源']]" ></home-doc-list>
        <home-doc-list class="doc_list_narrow" title-type="narrow" :docs="[categories['通知公告']]"></home-doc-list>
<!--        <home-doc-list :docs="[categories['学习专栏']]" :show-type="'banner'" style="width: 280px"></home-doc-list>-->

      </div>
      <div class="row-container">
        <home-banner-list style="width: 100%;"></home-banner-list>
      </div>
    </div>
    <HomeCard></HomeCard>
    <div style="max-width: 1220px;margin: 30px auto;">
      <home-doc-list :docs="[categories['抢险救援'],categories['工程项目']]" :show-type="'image'" ></home-doc-list>
    </div>
    <HomeFooter style="max-width: 1200px;margin: auto"></HomeFooter>
    <Footer></Footer>
  </div>
</template>

<script>
import HomeDocList from "@/components/Home/HomeDocList";
import HomeCarousel from "@/components/Home/HomeCarousel";
import Header from "@/components/Header";
import HomeCard from "@/components/Home/HomeCard";
import Footer from "@/components/Footer";
import BGImg from "@/components/BGImg";
import HomeFooter from "@/components/Home/HomeFooter";
import HomeBannerList from "@/components/Home/HomeBannerList";
export default {
  name: "Home",
  components: {HomeBannerList, HomeFooter, BGImg, Footer, HomeCard, Header, HomeCarousel, HomeDocList},
  data(){
    return{
      oknum:0,
      carousels:[],
      categories:{
        "公司动态":{id:1,sectionId:1},
        "国资要闻": {id:2,sectionId:1},
        "集团资讯": {id:3,sectionId:1},
        "时政新闻": {id:16,sectionId:1},
        "区域资讯": {id:17,sectionId:1},
        "市场经营": {id:4,sectionId:2},
        "党建工作": {id:5,sectionId:3},
        "通知公告": {id:6,sectionId:6},
        "人力资源": {id:7,sectionId:4},
        "学习专栏": {id:8},
        "抢险救援": {id:9},
        "工程项目": {id:10},
        "走马灯": {id:15},
      },
      banner_list: [],
    }
  },
  methods:{
    getDocList(categoryId){
      this.axios.get('/api/document/user/article/list/'+categoryId+'/').then(res=>{
        let data = res.data.results
        if(data.length > 0){
          let cid = data[0].category.categoryId;
          for(let name in this.categories){
            if(this.categories[name].id === cid){
              this.categories[name].doclist = data;
              break;
            }
          }
          this.oknum++;
        }
      })
    },
    getDocs(names){
      let docs = [];
      for(let name of names){
        let doc = {}
        doc.title = name;
        // this.getDocList(this.categories[name])
        docs.push(doc);
      }
      return docs;
    }
  },
  mounted() {
    for(let name in this.categories){
      this.getDocList(this.categories[name].id)
      this.categories[name].title = name;
    }
    this.axios({
      url:"/api/document/user/banner/list/",
      method:"get",
    }).then((res)=>{
      this.banner_list = res.data;
    })
    this.axios.get("/api/document/user/carousel/list/").then((res)=>{
      console.log('carousel,',res.data)
      this.carousels = res.data;
    })
  }
}
</script>

<style scoped>

@media screen and (min-width: 1000px) {
  .row-container {
    display: flex;
    margin-bottom: 40px;
  }
  .carousel{
    width: 691px;
  }
  .doc_list_wide{
    /*min-width: 369px;*/
    /*max-width: 480px;*/
    width: 480px;
  }
  .doc_list_narrow{
    width: 280px;
  }
}
</style>