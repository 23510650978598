<template>
  <div>
    <el-carousel :interval="9000" v-if="isHome" trigger="click" height="500px">
      <el-carousel-item v-for="item in bannerList" :key="item.bannerId">
        <img class="backgroundImg" :src="item.banner">
      </el-carousel-item>
    </el-carousel>
    <el-carousel v-else trigger="click" height="500px">
      <el-carousel-item v-for="item in 1" :key="item">
        <img class="backgroundImg" :src="imgUrl">
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "BGImg",
  props:{
    imgUrl: {
      type:String,
      default:'background.jpg'
    },
    isHome: {
      type:Boolean,
      default: false
    },
    bannerList: [],
  },
}
</script>

<style scoped>
.backgroundImg{
  width: 100%;
  height: 500px;
  object-fit: cover;
}
</style>
