<template>
  <div style="margin: 15px">
    <div class="title" :style="''+(titleType==''?'':'justify-content:space-between;')">
      <div v-for="(item,index) in docs">
        <home-doc-list-title :type="titleType" :content="item.title" :active="active===index"
                             @click.native="clickMore"
                             @mouseenter.native="change(index)"></home-doc-list-title>
      </div>
      <div v-if="needMore" class="more">
        <div @click="clickMore()" class="more_button">
          更多
        </div>
      </div>
    </div>
    <div class="containerlist">
      <div v-if="showType === 'text' && docs[active].doclist">
        <div v-if="docs[active].doclist.length" v-for="(item,index) in docs[active].doclist.slice(0,10)">
          <div class="doc" @click="clickDoc(item)">
            {{item.title}}
          </div>
        </div>
        <div v-if="!docs[active].doclist || docs[active].doclist.length === 0">
          <div class="empty">没有内容</div>
        </div>
      </div>
      <div v-if="showType === 'image'" class="image_carouse">
        <div v-if="docs[active].doclist && docs[active].doclist.length > 0" id="scrollElement" style="max-width: 1180px;display: flex;flex-flow: row nowrap;overflow: hidden">
          <div v-for="(item,index) in docs[active].doclist.concat(docs[active].doclist)" style="display: flex;">
            <div style="margin: 5px;position: relative">
              <img :src="item.cover" style="width: 285px;height:200px;object-fit: cover;" />
              <div @click="clickDoc(item)" class="image-text">{{item.title}}</div>
            </div>
          </div>
        </div>
        <div style="text-align: center">
          <div v-for="(item,index) in docs[active].doclist" style="display: inline-block;">
            <div :class="'imagebtn '+(image_active === index?'imagebtn_active':'')" @click="scroll(index)"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import HomeDocListTitle from "@/components/Home/HomeDocListTitle";
export default {
  name: "HomeDocList",
  components: {HomeDocListTitle},
  props: {
    docs:[],
    showType:{
      default:'text',
    },
    titleType:{
      type:String,
      default:''
    },
    needMore:false,
  },
  data() {
    return {
      active:0,
      image_active:0,
      image_moving:false,
    };
  },
  methods:{
    change(index){
      this.active = index
    },
    clickMore(){
      if(this.docs[this.active].sectionId)
        window.open('/doclist?id='+this.docs[this.active].sectionId+'&categoryid='+this.active)
    },
    clickDoc(item){
        if(item.external_link){
          window.open(item.external_link)
        }else{
          window.open("/document?id="+item.articleId)
        }
    },
    scroll(index){
      const dom = document.getElementById('scrollElement');
      this.image_active = index
      let target = 295*index;
      let delta = 1;
      // clearInterval(timer)
      if(this.image_moving){
        clearInterval(timer)
        this.image_moving = false;
      }
      this.image_moving = true;
      let timer = setInterval(()=>{
        let cur = dom.scrollLeft
        let dis = target - cur
        if(target > cur)
          dis = Math.ceil(dis * 0.05)
        else
          dis = Math.floor(dis * 0.05)
        cur += dis
        if(Math.abs(cur-target) <= 1){
          clearInterval(timer)
          this.image_moving = false;
        }
        if(Math.abs(cur-target) <= delta){
          cur = target
        }

        dom.scrollLeft = cur
      },1)
    }
  }
}
</script>

<style scoped>
.image_carouse{
  width: 300px;
  margin: auto;
}

@media screen and (min-width: 590px) {
  .image_carouse{
    width: 590px;
  }
}

@media screen and (min-width: 880px) {
  .image_carouse{
    width: 880px;
  }
}
@media screen and (min-width: 1200px) {
  .image_carouse{
    width: 1180px;
  }
}

.title{
  display: flex;
  border-bottom: 1px solid rgb(235, 239, 242);
  margin-bottom: 15px;
  position: relative;
  flex-direction: row;
  box-sizing: border-box;
  flex-wrap: wrap;
}
.containerlist{
  width: 100%;
}
.doc{
  flex: 1 1 0%;
  color: rgb(44, 50, 63);
  font-size: 14px;
  /*line-height: 25.2px;*/
  line-height: 28px;
  text-align: left;
  cursor: pointer;
  box-sizing: border-box;
  display: inline-block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.doc:hover{
  /*color: rgb(0, 51, 97);*/
  color: var(--primary_color);
}
.empty{
  text-align: left;
  color: rgb(153, 153, 153);
  font-size: 12px;
  box-sizing: border-box;
}
.more{
  height: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  box-sizing: border-box;
}
.more_button{
  padding: 6px 15px;
  font-size: 12px;
  cursor: pointer;
  background-color: var(--primary_color);
  color: white;
}
.imagebtn{
  border-radius: 7px;
  width: 9px;
  height: 9px;
  margin: 10px 5px;
  cursor: pointer;
  background-color: #b3b3b3;
}
.imagebtn_active{
  background-color: #007aff;
}
.image-text{
  margin-top: -32px;
  text-align: left;
  padding-left: 10px;
  background-color: white;
  color: white;
  cursor: pointer;
  
  font-size: 14px;
  line-height: 28px;

  height: 30px;
  position: absolute;
  width: 285px;

  /*border: solid 1px red;*/
  background: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
}
</style>