<template>
  <div>
    <div v-if="screenWidth>=1000" :class="{header: isUpper, header1:!isUpper}">
      <div :class="{search: isSearch, search1:!isSearch}">
        <div class="searchIcon">
          <i class="el-icon-search searchLeftButton"></i>
        </div>
        <div class="searchBox">
          <input r="30,0,0,30" placeholder="输入关键字搜索" class="searchInput" @keyup.enter="search" v-model="searchKey">
          <button type="submit" r="0,30,30,0" class="searchSubmit" @click="search">开始搜索</button>
        </div>
        <div class="cancelIcon">
          <el-button type="text" class="cancelButton" icon="el-icon-circle-close" @click="isSearch=false"></el-button>
        </div>
      </div>
      <div :class="{upper: isUpper, upper1:!isUpper}">
        <div class="left">
          <img src="../assets/AnHuaLogoWhite.svg" alt class="img">
        </div>

        <div class="right">
          <el-button class="button"  type="text" icon="el-icon-s-home" @click="link('/')">首页</el-button>
          <el-button class="button"  type="text" icon="el-icon-search" @click="isSearch=true">搜索</el-button>
        </div>
      </div>
      <div :class="{lower: isUpper, lower1:!isUpper}">
        <div class="buttonBox">
          <div
              class="inlineButton"
              v-for="item in menu"
              @click="link(item.url)"
          >{{item.title}}</div>
        </div>
      </div>
    </div>
    <div v-else class="small_header">
      <div style="width: 100%;color: white" :class="{small_up: isUpper, small_down:!isUpper}">
        <div style="float: left">
          <i @click="drawer = !drawer" class="el-icon-menu" style="margin: 8px 10px;cursor:pointer;">&nbsp;菜单</i>
        </div>

        <div style="float:right;">
          <el-button style="color: white;margin-right: 15px;" type="text" icon="el-icon-search" @click="$router.push('/search?q=')">搜索</el-button>
        </div>
      </div>

      <el-drawer
          title="我是标题"
          :visible.sync="drawer"
          direction="ttb"
          :modal="false"
          size="50%"
          :with-header="false">
        <div style="width: 100%;height: 30px">

        </div>
        <div class="small_menu" v-for="item in menu" @click="link(item.url) && (drawer = false)">
          {{ item.title }}
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data(){
    return {
      scrollY:0,
      isUpper:true,
      isSearch:false,
      isSmallSerach:false,
      searchKey:'',
      drawer: false,
      menu:[
        {title:'首页', url:'/'},
        {title:'公司概况', url:'/brief'},
        {title:'新闻中心', url:'/doclist?id=1'},
        {title:'党的建设',  url:'/doclist?id=3'},
        {title:'业务领域', url:'/doclist?id=2'},
        {title:'企业文化', url:'/doclist?id=5'},
        // {title:'视频中心', url:'/videoList'},
        {title:'信息公开', url:'/doclist?id=13'},
        {title:'联系我们', url:'/about'},
      ],
      screenWidth: document.body.clientWidth,
    }
  },
  //自动触发
  mounted: function () {
    window.addEventListener('scroll', this.handleScroll, true)
    // window.onresize = () => {
    //   if(!this.timer){ // 使用节流机制，降低函数被触发的频率
    //     this.timer = true;
    //     let that = this; // 匿名函数的执行环境具有全局性，为防止this丢失这里用that变量保存一下
    //     setTimeout(function(){
    //       that.screenWidth = document.body.clientWidth;
    //       that.timer = false;
    //     },40)
    //   }
    // }
    // this.axios({
    //   url:"/document/admin/section/list/",
    //   method:"get",
    // }).then((res)=>{
    //   // console.log('articles', res);
    //   this.menu = res.data;
    // })
    // console.log(this.handleScroll)
  },
  // 页面销毁前
  beforeDestroy: function () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods:{
    handleScroll() {
      this.scrollY = document.documentElement.scrollTop
      // console.log(
      //     '滑动高度',
      //     this.scrollY,
      // )
      this.isUpper = this.scrollY <= 120;
    },
    link(url){
      window.location.href = url;
    },
    search(){
      // alert(this.searchKey)
      this.$router.push('/search?q='+this.searchKey)
    },
  }
}
</script>

<style scoped>
.small_header{
  display: flex;
  position: fixed;
  float: top;
  top: 0;
  width: 100%;
  z-index: 99;
  /*background-color: var(--primary_color);*/
}
.small_menu{
  margin-top: 18px;
  font-size: 18px;
  cursor: pointer;
}
.small_up{
  transition: all 0.3s ease 0s;
}
.small_down{
  background-color: rgba(0,0,0, 0.6);
  z-index: 99;
  transition: all 0.3s ease 0s;

}
.header{
  display: flex;
  position: fixed;
  float: top;
  top: 0;
  width: 100%;
  height: 138px;
  transition: all 0.3s ease 0s;
  z-index: 99;
  /*background-color: var(--primary_color);*/
}
.header1{
  display: flex;
  position: fixed;
  float: top;
  top: 0;
  width: 100%;
  height: 48px;
  transition: all 0.3s ease 0s;
  z-index: 99;
  background-color: rgba(0, 0, 0, .85);
}
.upper{
  margin: auto;
  //margin-top: 0;
  transition: all 0.5s ease 0s;
  height: 90px;
  width: 1200px;
}
.upper1{
  margin-top: -90px;
  transition: all 0.5s ease 0s;
  height: 90px;
  width: 1200px;
}
.left{
  float: left;
}
.right{
  float: right;
}
.img{
  object-fit: cover;
  height: 30px;
}
.button{
  display: inline-block;
  width: 60px;
  opacity: 0.6;
  transition: all 0.5s ease 0s;
  text-shadow: rgb(0 0 0) 1px 1px 1px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  line-height: 14px;
  box-sizing: border-box;
}
.button:hover {
  opacity: 1;
}
.lower{
  position: fixed;
  width: 100%;
  height: 48px;
  transition: all 0.3s ease 0s;
  z-index: 3;
  top: 90px;
  left: 0;
  background: rgba(255, 255, 255, 0.2);
}
.lower1{
  position: fixed;
  width: 100%;
  height: 48px;
  transition: all 0.3s ease 0s;
  z-index: 3;
  top: 0;
  left: 0;
  background: rgba(50, 50, 50, 1);
}
.buttonBox{
  display: flex;
  width: 1200px;
  margin: auto;
}
.inlineButton{
  display: inline-block;
  //position: center;
  line-height: 48px;
  text-align: center;
  text-shadow: rgb(0 0 0) 1px 1px 1px;
  margin: auto;
  height: 48px;
  width: 80px;
  //margin-left: 50px;
  opacity: 0.7;
  color: rgb(255, 255, 255);
}
.inlineButton:hover {
  opacity: 1;
  font-weight: bolder;
  //line-height: 46px;
  cursor: pointer;
  height: 46px;
  border-bottom: white inset 2px;
}

.search {
  position: fixed;
  display: flex;
  width: 100%;
  top: 0;
  height: 68px;
  transition: all 0.5s ease 0s;
  background: rgb(255, 255, 255);
  z-index: 99;
}
.search1 {
  position: fixed;
  display: flex;
  width: 100%;
  top: -68px;
  height: 68px;
  transition: all 0.5s ease 0s;
  background: rgb(255, 255, 255);
  z-index: 99;
}
.searchIcon{
  display: flex;
  background: rgb(255, 255, 255);
  width: 10%;
  height: 100%;
}
.searchLeftButton{
  /*margin: auto;*/
  margin-top: 19px;
  font-size: 30px;
  color: #333333;
  width: 100%;
  height: 100%;
}
.cancelIcon{
  background: rgb(255, 255, 255);
  width: 10%;
  height: 100%;
}
.cancelButton{
  margin: auto;
  font-size: 30px;
  color: #333333;
  width: 100%;
  height: 100%;
}
.searchBox {
  margin: auto;
  display: flex;
  width: 100%;
  height: 35px;
  /*flex-direction: row;*/
  /*flex: 1 1 0%;*/
  background: rgb(255, 255, 255);
  /*box-sizing: border-box;*/
}
.searchInput {
  width: 100%;
  border: 1px solid rgba(0, 63, 119, 0.1);
  outline: none;
  /*white-space: nowrap;*/
  /*vertical-align: middle;*/
  background: rgb(247, 248, 249);
  padding: 6px 15px;
  font-size: 12px;
  border-radius: 30px 0 0 30px;
  color: rgb(44, 50, 63);
  line-height: 1.42857;
  /*-webkit-writing-mode: horizontal-tb !important;*/
}
.searchSubmit {
  transition: all 0.5s ease 0s;
  outline: none;
  width: 120px;
  /*white-space: nowrap;*/
  /*vertical-align: middle;*/
  cursor: pointer;
  background: rgb(0, 63, 119);
  text-align: center;
  touch-action: manipulation;
  /*appearance: button;*/
  padding: 6px 15px;
  font-size: 12px;
  border: 1px solid rgb(0, 63, 119);
  border-radius: 0 30px 30px 0;
  color: rgb(255, 255, 255);
  line-height: 1.42857;
  /*-webkit-writing-mode: horizontal-tb !important;*/
}
</style>
