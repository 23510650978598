<template>
  <div>
    <div class="name_container">
      <div v-if="type === 'wide'">
        <div v-if="!active">
          <div class="name_w">{{content}}</div>
        </div>
        <div v-if="active">
          <div class="name_w name_w_active">{{content}}</div>
        </div>
      </div>
      <div v-else-if="type === 'narrow'">
        <div class="name_n">{{content}}</div>
      </div>
      <div v-else>
        <div v-if="!active">
          <div class="name">{{content}}</div>
        </div>
        <div v-if="active">
          <div class="name name_active">{{content}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeDocListTitle",
  props: {
    content: {
      // 定义接收的类型 还可以定义多种类型 [String, Undefined, Number]
      // 如果required为true,尽量type允许undefined类型，因为传递过来的参数是异步的。或者设置默认值。
      type: String,
      // 定义是否必须传
      required: true,
      // 定义默认值
      default: '暂无'
    },
    active:{
      type: Boolean,
    },
    type:{
      type:String,
      required: false,
      default: ''
    }
  },
}
</script>

<style scoped>
.name_container{
  height: 48px;
  margin-bottom: -8px;
  margin-right: 20px;
  line-height: 40px;
  cursor: pointer;
  box-sizing: border-box;
}
.name{
  height: 48px;
  border-bottom: 0px solid rgb(0, 63, 119);
  font-size: 18px;
  line-height: 48px;
  text-align: center;
  box-sizing: border-box;
  color: #000000d9;
}
.name_active{
  color: var(--primary_color);
  border-bottom: 2px solid rgb(0, 63, 119);
}
.name_n{
  background-color: #018dd7;
  color: white;
  font-weight: bolder;
  font-size: 16px;
  padding: 0 10px;
}
.name_w{
  color: #333333;
  font-size: 17px;
  font-weight: bolder;
}
.name_w_active{
  color: #018dd7;
  font-size: 18px;
}
</style>