<template>
<div style="background: rgba(0, 63, 119, 0.12);max-width: 1200px;margin: auto" >
  <div class="container_right">
    <div v-for="(item,index) in context"  style="display: flex;">
      <div class="card_out">
        <div class="card" :style="'background:'+item.backgroudcolor " @click="open(item.link)">
          <div class="card_img">
            <el-image :src="item.image" style="height:60%;margin-top: 10px"/>
          </div>
          <div style="margin-bottom: 10px;">
            {{item.name}}
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
</template>

<script>
export default {
  name: "HomeCard",
  data(){
    return{
      context:[
        {
          name:'安能通',
          backgroudcolor:'rgba(192, 0, 14, 0.06)',
          link:'https://oa.china-an.cn/',
          image:"homecard/0.svg",
        },
        {
          name:'财务共享平台',
          backgroudcolor:'rgba(0,63,119,0.08)',
          link: 'https://fssc.china-an.cn/',
          image:"homecard/2.svg",
        },
        {
          name:'极简报销',
          backgroudcolor:'rgba(192, 0, 14, 0.06)',
          link: 'https://jjbx.czbank.com/login',
          image:"homecard/jijian.png",
        },
        {
          name:'企业邮箱',
          backgroudcolor:'rgba(0, 63, 119, 0.08)',
          link: 'https://exmail.qq.com/login',
          image:"homecard/3.png",
        },
      ]
    }
  },
  methods:{
    open(url){
      window.open(url)
    }
  }
}
</script>

<style scoped>
.container_right{
  max-width: 1220px;
  display: flex;
  /*height: 179px;*/
  /*padding-bottom: 50px;*/
  margin: auto;
  flex-wrap: wrap;
  justify-content: center;
}
.card_out{
  padding: 30px 5px;
}
.card{
  /*border: 1px solid rgba(0, 63, 119, 0.1);*/
  padding: 10px;
  cursor: pointer;
}
.card_img{
  height: 60px;width: 268px;
}
.card_img:hover{
  -webkit-transform: scale(1.1);
  -webkit-transition: all .3s ease;
}
</style>