<template>
<div>
  <div class="separation"> </div>
  <div style="display: flex;width: 100%;flex-wrap: wrap;justify-content: center;margin-bottom: 30px;">
    <div v-for="item in links" style="display: flex;flex: none;margin: 5px 5px;">
      <div style="display: inline-block;" class="link_text" @click="open(item.link)">
        {{item.name}}
      </div>
    </div>
  </div>

</div>
</template>

<script>
export default {
  name: "HomeFooter",
  data(){
    return{
      links:[
        {name:"中国政府网",link:"https://www.gov.cn/"},
        {name:"国务院国资委",link:"http://www.sasac.gov.cn/"},
        {name:"应急管理部",link:"https://www.mem.gov.cn/"},
        {name:"中央企业",link:"http://www.sasac.gov.cn/n4422011/n14158800/n14158998/c14159097/content.html"},
        {name:"上海市应急管理局",link:"http://yjglj.sh.gov.cn/"},
        {name:"江苏省应急管理厅",link:"http://safety.jiangsu.gov.cn/"},
        {name:"浙江省应急管理厅",link:"https://yjt.zj.gov.cn/"},
        {name:"安徽省应急管理厅",link:"http://yjt.ah.gov.cn/"},
        {name:"上海市水务局",link:"http://swj.sh.gov.cn/"},
        {name:"集团公司",link:"https://www.china-an.cn/"},
        {name:"第一工程局",link:"https://www.andygcj.com"},
        {name:"第二工程局",link:"http://www.andegcj.cn/Index.aspx"},
        {name:"第三工程局",link:"http://www.china-an3.cn/"},
        {name:"安能科工",link:""},
      ]
    }
  },
  methods:{
    open(url){
      window.open(url)
    }
  }
}
</script>

<style scoped>
.separation{
  width: 100%;
  height: 6px;
  background-color: var(--primary_color);
  margin: 20px auto 20px auto;

}
.link_text{
  outline: none;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  background: rgb(255, 255, 255);
  text-align: center;
  touch-action: manipulation;
  padding: 8px 18px;
  font-size: 12px;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 0px;
  color: rgb(44, 50, 63);
  line-height: 1.33333;
  -webkit-writing-mode: horizontal-tb !important;
}
</style>