<template>
  <div style="margin-right: 12px;">
    <el-carousel trigger="click" :interval="5000" height="410px" indicator-position="none">
      <el-carousel-item v-for="(item,index) in data" :key="index" >
        <img :src="item.article.cover" style="object-fit: cover;
              height: 100%;
              width: 100%;"/>
        <div class="image-text" @click="clickDoc(item)">{{item.article.title}}</div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "HomeCarousel",
  props:{
    data:[]
  },
  methods:{
    clickDoc(item){
      if(item.article.external_link){
        window.open(item.article.external_link)
      }else{
        window.open('/document?id='+item.article.articleId)
      }
    }
  },
  mounted() {
    console.log(this.data)
  }
}
</script>

<style scoped>
.image-text{
  margin-top: -36px;
  text-align: left;
  padding-top: 2px;
  padding-left: 10px;
  background-color: white;
  color: white;
  cursor: pointer;
  font-size: 17px;
  line-height: 28px;

  height: 35px;
  position: absolute;
  width: 100%;

  background: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
}
</style>