import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// ==============  elementUI ===============
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)

// ==============  公共css引入 ===============
import './assets/index.css'

// ==============  axios  ==================

import axios from 'axios'

// axios.defaults.baseURL='http://127.0.0.1:8000';
// axios.defaults.baseURL='http://192.168.63.134:8000';
// axios.defaults.baseURL='https://anhdtz.cn/';
// axios.defaults.baseURL='http://114.242.110.67';

Vue.prototype.axios = axios
axios.interceptors.response.use(res => {
  return res;
}, error => {
  console.log(error.response.status)
  if (error.response.status) {
    switch (error.response.status) {
      case 401:
      case 403:
        window.localStorage.clear()
        router.push('/login')
        break;
    }
  }
  return Promise.reject(new Error(error));
})

// ==============  视频播放 ===============

import vueMiniPlayer from 'vue-mini-player'
import 'vue-mini-player/lib/vue-mini-player.css'
Vue.use(vueMiniPlayer)

// ==============  视频播放2 ===============
import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
