<template>
  <div>
    <footer class="footer">
      <div class="footerUpper">
        <img src="../assets/AnHuaLogoWhite.svg">
      </div>
      <div class="text">
        <div class="footerLower">
          <a href="/" target="_blank" class="footerText footerLink">
            <span>中国安能集团华东投资开发有限公司</span>
          </a>
          <span class="footerText">版权所有</span>
          <a href="https://beian.miit.gov.cn" target="_blank" class="footerText footerLink">
            <span>ICP备04000001号-1</span>
          </a>
        </div>
        <div class="footerLower">
          <span class="footerText">地址：上海市静安区汶水路299弄18号</span>
        </div>
        <div class="footerLower">
          <span class="footerText">邮箱：hdtzkfyxgs@china-an.cn</span>
        </div>
        <div class="footerLower">
          <span class="footerText">电话：021-62890618</span>
        </div>
      </div>

      <br>
    </footer>
  </div>
</template>

<script>
// 中国安能集团华东投资开发有限公司  版权所有  ICP备04000001号-1
// 地址：上海市静安区汶水路299弄18号
// 邮箱：hdtzkfyxgs@china-an.cn
// 电话：021-62890618
export default {
  name: "Footer"
}
</script>

<style scoped>
.footer{
  display: block;
  /*height: 163px;*/
  background-color: var(--primary_color);
  /*background-color: rgba(0, 101, 192, 1);*/

  z-index: 2;
  box-sizing: border-box;
}
.footerUpper{
  display: inline-block;
  margin: 30px auto;
  //line-height: 36px;
  height: 36px;
  max-width: 582px;
  width: 100%;
}
.text{
  width:438px;
  /*max-width: 582px;*/
  margin: auto;
  text-align: left;
}
.footerLower{
  //display: inline-block;
  margin: auto;
  /*margin-bottom: 20px;*/
  //line-height: 36px;
}
.footerText{
  padding: 5px;
  /*opacity: 0.8;*/
  color: rgba(255, 255, 255);
  font-size: 14px;
  line-height: 22px;
  text-decoration:none;
  transition: all 0.5s ease 0s;
}
.footerLink:hover{
  opacity: 0.8;
  text-shadow: rgb(0 0 0) 1px 1px 1px;
}
</style>
