import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import axios from 'axios'
import router from "@/router";

// axios.defaults.baseURL='http://127.0.0.1:8000';
// axios.defaults.baseURL='http://192.168.63.134:8000';
// axios.defaults.baseURL='http://114.242.110.67:8000/api';
// axios.defaults.baseURL='http://114.242.110.67';

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  config.headers['Authorization'] = window.localStorage.getItem('token');
  // config.headers['token'] = window.localStorage.getItem('token');
  return config;
})
axios.interceptors.response.use(res => {
  return res;
}, error => {
  console.log(error.response.status)
  if (error.response.status) {
    switch (error.response.status) {
      case 401:
      case 403:
        window.localStorage.clear()
        router.push('/login')
        break;
    }
  }
  return Promise.reject(new Error(error));
})

export default new Vuex.Store({
  state: {
    article_list: [],
    section_list: [],
    category_list: [],
    category_filter:[],
    section_now: {
      sectionId:1,
    },
    article_editing:{},
    category_editing:{},
    adminState:'main',
    isEditing:false,// 弃用
    tabsValue:'articleList',
    docListPaths:[],
    video_list: [],
    video_editing:{},
    banner_list: [],
    banner_editing:{},
    carousel_list: [],
    carousel_editing:{},
  },
  mutations: {
    updateSectionList(state){
      axios({
        url:"/api/document/admin/section/list/",
        method:"get",
      }).then((res)=>{
        // console.log('sections', res);
        state.section_list = res.data;
      })
    },
    updateCategoryList(state, section){
      let sid = '';
      if (section !== undefined)
        sid = section.sectionId
      axios({
        url:"/api/document/admin/category/list/"+sid,
        method:"get",
      }).then((res)=>{
        // console.log('articles', res);
        state.category_list = res.data;
        state.category_filter = state.category_list.map((item, index)=>{
          return {
            text: item.title,
            value: item.title
          }
        })
      })
    },
    updateArticleList(state, section){
      let sid = '';
      if (section !== undefined)
        sid = section.sectionId
      axios({
        url:"/api/document/admin/article/list/"+sid,
        method:"get",
      }).then((res)=>{
        // console.log('articles', res);
        state.article_list = res.data;
      })
    },
    updateVideoList(state){
      axios({
        url:"/api/video/admin/list/",
        method:"get",
      }).then((res)=>{
        // console.log('articles', res);
        state.video_list = res.data;
      })
    },
    updateBannerList(state){
      axios({
        url:"/api/document/admin/banner/list/",
        method:"get",
      }).then((res)=>{
        // console.log('articles', res);
        state.banner_list = res.data;
      })
    },
    updateCarouselList(state){
      axios({
        url:"/api/document/admin/carousel/list/",
        method:"get",
      }).then((res)=>{
        console.log('ccc', res);
        state.carousel_list = res.data;
      })
    },
    setSectionNow(state, section){
      state.section_now = section;
    },
    setIsEditing(state, isEditing){
      state.isEditing = isEditing
    },
    setAdminState(state, adminState){
      state.adminState = adminState
    },
    setTabsValue(state, tabsValue){
      state.tabsValue = tabsValue;
      console.log(state.tabsValue);
    },
    setArticleEditing(state, article){
      state.article_editing = article;
    },
    setVideoEditing(state, video){
      state.video_editing = video
    },
    setBannerEditing(state, banner){
      state.banner_editing = banner
    },
    setCarouselEditing(state, carousel){
      state.carousel_editing = carousel
    },
    setCategoryEditing(state, category){
      state.category_editing = category;
    },
    initData(state){
      this.commit('updateSectionList');
      this.commit('updateCategoryList', state.section_now);
      this.commit('updateArticleList', state.section_now);
      this.commit('updateVideoList');
      this.commit('updateBannerList');
      this.commit('updateCarouselList');
    },
    setDocListPaths(state, paths){
      state.docListPaths = []
      for(let path of paths){
        state.docListPaths.push(path)
      }
      console.log(paths)
    }
  },
  actions: {
    updateACList(context, section){
      console.log('now', section);
      context.commit('setSectionNow', section);
      context.commit('updateArticleList', section);
      context.commit('updateCategoryList', section);
    },
    // updateArticleEditing(context, article){
    //   context.commit('setArticleEditing', article);
    //   context.commit('setIsEditing', true);
    //   context.commit('setTabValue', 'edit');
    // },
  },
  modules: {
  }
})
