import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('../views/test.vue')
  },
  {
    path: '/testdoc',
    name: 'testdoc',
    component: () => import('../views/testdoc')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/admin/admin.vue')
  },
  {
    path: '/doclist',
    name: 'DocList',
    component: () => import('../views/DocList')
  },
  {
    path: '/document',
    name: 'Document',
    component: () => import('../views/Document')
  },
  {
    path: '/brief',
    name: 'Brief',
    component: () => import('../views/Brief')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/admin/Login')
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('../views/Search')
  },
  {
    path: '/videoList',
    name: 'VideoList',
    component: () => import('../views/VideoList')
  },
  {
    path: '/video',
    name: 'Video',
    component: () => import('../views/Video')
  },
  {
    path: '/videoEdit',
    name: 'VideoEdit',
    component: () => import('../views/admin/components/VideoEdit')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
